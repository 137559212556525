<template>
	<div class="address-edit">
		<div class="edit-row">
			<span>姓名</span>
			<input type="text" placeholder="请输入姓名" v-model="contacts" />
		</div>
		<div class="edit-row">
			<span>手机号</span>
			<input type="text" placeholder="请输入手机号" v-model="contact_phone" />
		</div>
		<div class="edit-row">
			<span>所在地区</span>
			<!-- <div id="area">{{region}}</div> -->
			<div @click="isShowRegion = true">{{ region }}</div>
			<van-popup v-model="isShowRegion" position="bottom">
				<van-area title="省/市/区" :area-list="areaList" :value="region_areaid.toString()" @change="onChange"
					@confirm="isShowRegion = false" @cancel="isShowRegion = false" ref="region" />
			</van-popup>
		</div>
		<div class="edit-row row1">
			<span>详细地址</span>
			<textarea placeholder="请输入详细地址" v-model="address"></textarea>
		</div>
		<div class="default-row">
			<span>设为默认</span>
			<van-switch v-model="is_default" active-color="#3CB30C" inactive-color="#F1F0F0" />
		</div>
		<div class="btn-sub" @click="submit()">提交</div>
	</div>
</template>
<script>
	import {
		areaList
	} from "@/common/vant_city";
	import {
		createAddress,
		getAddressDetail,
		updateAddress
	} from "@/http/api";
	export default {
		components: {},
		data() {
			return {
				userToken: "",
				isShowRegion: false,
				contacts: "",
				contact_phone: "",
				address: "",
				region: "请选择所在区域",
				region_provinceid: "",
				region_cityid: "",
				region_areaid: "",
				is_default: false,
				areaList: areaList,
				isPost: false,
				type: "",
				id: 0
			};
		},
		created() {
			this.type = this.$route.query.type;
			switch (this.type) {
				case "add":
					document.title = "添加地址";
					break;
				case "edit":
					document.title = "编辑地址";
					break;
			}
		},		
		mounted() {
			this.userToken = this.$LStorage.getItem("userToken").user_token;
			if (this.type == "edit" && this.$route.query.id) {
				this.id = this.$route.query.id;
				this.getAddressDetail();
			}
		},
		methods: {
			onChange(picker, value, index) {
				//<!--打印看下对应值-->
				// console.log("当前值：" + value[0].code + "当前索引：" + index);
				//<!--因为解析过来的数据是数组而carmodel定义字符串-->
				let areaName = "";
				let regionCode = "";
				for (var i = 0; i < value.length; i++) {
					areaName = areaName + value[i].name + " ";
				}
				this.region = areaName;
				this.region_provinceid = value[0].code;
				this.region_cityid = value[1].code;
				this.region_areaid = value[2].code;
			},
			// 获取地址详情
			async getAddressDetail() {
				const res = await getAddressDetail({
					data: {
						user_token: this.userToken,
						address_id: this.id
					}
				});
				if (res.code == 200) {
					this.contacts = res.data.contacts;
					this.contact_phone = res.data.contact_phone;
					this.region = res.data.region;
					this.region_provinceid = res.data.region_provinceid;
					this.region_cityid = res.data.region_cityid;
					this.region_areaid = res.data.region_areaid;
					this.address = res.data.address;
					this.is_default = res.data.is_default == 0 ? false : true;
				} else {
					this.$dialog.alert({
						message: "获取用户地址信息错误"
					}).then(() => {
						this.$router.go(-1);
					})
				}
			},

			// 前端验证
			submit() {
				if (this.isPost) {
					this.$toast("请勿重复提交");
				} else {
					if (this.contacts == "") {
						this.$toast("请输入姓名");
						return;
					} 
					if (this.contact_phone == "") {
						this.$toast("请输入手机号");
						return;
					}
					if (this.region == "请选择所在区域") {
						this.$toast("请选择所在区域");
						return;
					}
					if (this.address == "") {
						this.$toast("请输入详细地址");
						return;
					}
					this.createAddress();
				}
			},
			
			// 添加/修改地址
			async createAddress() {
				this.isPost = true;
				let res;
				if (this.type == "add") {
					res = await createAddress({
						data: {
							user_token: this.userToken,
							contacts: this.contacts,
							contact_phone: this.contact_phone,
							address: this.address,
							region: this.region,
							region_provinceid: this.region_provinceid,
							region_cityid: this.region_cityid,
							region_areaid: this.region_areaid,
							is_default: this.is_default ? 1 : 0
						}
					});
				} else if (this.type == "edit") {
					res = await updateAddress({
						data: {
							address_id: this.id,
							user_token: this.userToken,
							contacts: this.contacts,
							contact_phone: this.contact_phone,
							address: this.address,
							region: this.region,
							region_provinceid: this.region_provinceid,
							region_cityid: this.region_cityid,
							region_areaid: this.region_areaid,
							is_default: this.is_default ? 1 : 0
						}
					});
				}
				this.isPost = false;
				if (res.code == 200) {
					this.$router.go(-1);
				} else {
					this.$toast(res.msgs);
				}
			}
		},

		computed: {},
		filters: {}
	};
</script>
<style lang="scss" scoped>
	.address-edit {
		* {
			box-sizing: border-box;
		}

		min-height: 100vh;
		background: #f6f6f6;

		.edit-row {
			display: flex;
			align-items: center;
			background: #fff;
			font-size: 0.3rem;
			min-height: 14vw;
			padding: 0 3vw;
			border-bottom: 1px solid #eee;

			span {
				display: block;
				width: 4em;
				margin-right: 0.5rem;
			}

			.right {
				outline: none;
			}
		}

		.row1 {
			flex-wrap: wrap;
			border-bottom: none;

			span {
				width: 100%;
				height: 14vw;
				line-height: 14vw;
			}

			textarea {
				resize: none;
				border: 1px solid #eee;
				padding: 1em;
				font-size: 0.24rem;
				height: 16vw;
				width: 100%;
				margin-bottom: 3vw;
			}
		}

		.default-row {
			height: 14vw;
			background: #fff;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			padding: 0 3vw;
			font-size: 0.3rem;
			margin-bottom: 10vw;

			img {
				height: 0.8rem;
				width: auto;
				margin-left: 0.5rem;
			}
		}

		.van-switch {
			box-sizing: content-box;
			margin-left: 0.2rem;
		}

		.btn-sub {
			width: 94vw;
			height: 0.75rem;
			border-radius: 0.75rem;
			background: #e50012;
			color: #fff;
			font-size: 0.32rem;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0 3vw;
		}
	}
</style>
